<template>
  <b-modal
    ref="addOppurtunityModal"
    no-close-on-esc
    no-close-on-backdrop
    centered
    :title="$t('opportunities.addOpportunity.title')"
    @show="showModal"
    @close="backToList"
    @cancel="backToList"
  >
    <cp-input-container
      ref="cpInputContainer"
      v-model="model"
      class="mb-3"
      @edited="editHandler"
    >
      <cp-select
        v-model="model.tokenId"
        :label="$t('opportunities.addOpportunity.tokenSelect.label')"
        name="tokenId"
        :required="true"
        :options="issuerTokensOptions"
        validate="required"
      />
      <cp-input
        v-model="model.title"
        :class="'mt-3'"
        name="title"
        :label="$t('opportunities.addOpportunity.titleText.label')"
        validate="required"
      />
    </cp-input-container>
    <b-alert
      v-if="hasError"
      variant="danger"
      show
    >
      {{ serverError }}
    </b-alert>

    <template #modal-footer="{ cancel }">
      <b-button
        @click="cancel"
      >
        {{ $t('opportunities.addOpportunity.cancelButtonText') }}
      </b-button>
      <cp-button
        :disabled="saving"
        size="md"

        variant="primary"
        :is-loading="saving"
        @click="saveChanges"
      >
        {{ $t('opportunities.addOpportunity.createOpportunityButtonText') }}
      </cp-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import cpInputContainer from '~/components/common/cpInputContainer';
import routeNames from '~/utilities/routes';

export default {
  name: 'AddOpportunityModal',
  components: {
    CpSelect,
    CpButton,
    CpInput,
    cpInputContainer,
  },
  metaInfo: {
    title: 'Add Opportunity',
  },
  data() {
    return {
      hasError: false,
      serverError: '',
      saving: false,
      currRounds: [],
      model: {
        tokenId: this.$route.params.tokenId,
        title: '',
      },
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo']),
    ...mapGetters('rounds', ['roundsOption']),
    issuerTokensOptions() {
      return (this.issuerInfo.tokens || []).map(item => ({
        text: item.name,
        value: item.id,
      }));
    },
  },
  mounted() {
    this.showModal();
  },
  methods: {
    ...mapActions('opportunities', ['createOpportunity']),
    saveChanges(env) {
      this.hasError = false;
      env.preventDefault();
      this.$refs.cpInputContainer.validateForm()
        .then(async () => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.saving = true;
            try {
              const { data: { id, tokenId } } = await this.createOpportunity({ issuerId: this.$route.params.idIssuer, opportunity: this.model });
              const { idIssuer } = this.$route.params;
              const route = routeNames.assetContent(idIssuer, tokenId);
              this.$router.push({ path: route, query: { id } });
            } catch (x) {
              if (x && x.response && x.response.data && x.response.data.message) {
                this.hasError = true;
                this.serverError = x.response.data.message;
              }
            }
            this.saving = false;
          }
        });
    },
    showModal() {
      this.$refs.addOppurtunityModal.show();
    },
    backToList(env) {
      env.preventDefault();
      const { idIssuer } = this.$route.params;
      this.$router.push(routeNames.opportunitiesConfiguration(idIssuer));
    },
    editHandler() {
      this.hasError = false;
    },
  },
};
</script>
